
































import { Config } from 'client-website-ts-library/services/Config';
import { Component, Vue, Prop } from 'vue-property-decorator';

import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class Header extends Vue {
  @Prop()
  public readonly mobileNavActive!: boolean;

  @Prop()
  public readonly isStuck!: boolean;

  public readonly agentId = Config.API.WebsiteId;
}
