







































































































import { Component, Vue } from 'vue-property-decorator';
import { Profile, WebsiteLevel } from 'client-website-ts-library/types';
import { API, Config, Logger, LogLevel } from 'client-website-ts-library/services';

@Component
export default class Footer extends Vue {
  public profile: Profile | null = null;

  mounted() {
    this.loadOffices();
  }

  loadOffices(): void {
    API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
      this.profile = profile;
    });
  }

  // eslint-disable-next-line
  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  // eslint-disable-next-line
  get year(): string {
    return new Date().getFullYear().toString();
  }

  get hideMap(): boolean {
    return this.$route.meta?.hideOfficeMap;
  }
}
